<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";

export default {
  components: { Vertical, Horizontal },
  props: {
    hideLayout: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed,
  },
  methods: {},
};
</script>

<template>
  <div>
    <div class="container" v-if="hideLayout">
      <div class="text-center" style="margin-top: 28px;">
        <div>
          <!-- <a href="/" class="logo"> -->
            <span class="text-muted">Welcome to</span><br>
            <img src="@/assets/images/logo-dark.png" height="40" alt="logo" />
          <!-- </a> -->
        </div>

        <!-- <h4 class="font-size-18 mt-0">Hello!</h4> -->
        <p class="text-muted">__________________  <br> Fill out the form to initiate a land purchase</p>
      </div>
      <slot />
    </div>
    <div v-else>
      <vertical v-if="layoutType === 'vertical'" :layout="layoutType">
        <slot />
      </vertical>

      <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
        <slot />
      </Horizontal>
    </div>
  </div>
</template>
